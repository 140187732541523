import { ref } from "vue";
import { UploadUrl } from "@/api/GoodsApi";
import { GetImageSize } from "@/common/utils";
import { ElMessage } from "element-plus";
export default function Logo(props, { emit }) {
    const dialogInfo = props.data;
    const ruleForm = ref(null);
    const rules = {
        pic: [{ required: true, message: "请上传图片", trigger: "blur" }],
        picUrl: [{ required: true, message: "请输入图片链接", trigger: "blur" }],
    };
    const uploadUrl = UploadUrl;
    const beforeDetailUpload = (val) => {
        return new Promise((reslove, reject) => {
            GetImageSize(val).then((res) => {
                if (res.width != 750 || res.height != 376) {
                    ElMessage.error("图片上传失败，图片大尺寸为750*376");
                    reject();
                }
                reslove();
            });
        });
    };
    const cancel = () => {
        ruleForm.value.resetFields();
        emit("cancel");
    };
    const submit = () => {
        ruleForm.value.validate((valid) => {
            if (valid) {
                emit("submit", dialogInfo);
            }
            else {
                return false;
            }
        });
    };
    const uploadImg = (value) => {
        dialogInfo.pic = value.resultData;
    };
    const remove = () => {
        dialogInfo.pic = '';
    };
    return { dialogInfo, cancel, submit, ruleForm, rules, uploadUrl, beforeDetailUpload, uploadImg, remove };
}

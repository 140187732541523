import { AddAdvertBanner, DelAdvertBanner, EditAdvertBanner, GetAdvertBanner, sortAdvertBanner } from "@/api/GoShopApi";
import ShopService from "@/services/Shop/ShopService";
import { ElMessage, ElMessageBox } from "element-plus";
import { onMounted, reactive, ref } from "vue";
class Logo {
}
class DiaInfo {
    constructor() {
        this.components = 'BigLogo';
    }
}
export default function AdvertList() {
    const id = ShopService.GetCurrentShop().id;
    const loading = ref(false);
    const dialogVisible = ref(false);
    const dialogInfo = reactive(new DiaInfo());
    const isEdit = ref(false);
    const picType = ref(0);
    const PcAdList = reactive(new Array()); // Pc广告图
    const H5AdList = reactive(new Array()); // H5广告图
    onMounted(() => {
        initBannerList(0);
        initBannerList(1);
    });
    const addType = reactive({
        "BigLogo": { id: 2, name: '添加广告图（大）', comp: 'BigLogo' },
        "Logo": { id: 3, name: '添加广告图（小）', comp: 'Logo' } // 添加广告图（小）
    });
    const onDrag = (type, arg) => {
        const newIndex = arg.newIndex;
        const oldIndex = arg.oldIndex;
        if (newIndex == oldIndex)
            return;
        const ids = (type == 0 ? PcAdList : H5AdList).map((item) => {
            return item.id;
        });
        const temp = ids[newIndex];
        ids[newIndex] = ids[oldIndex];
        ids[oldIndex] = temp;
        loading.value = true;
        sortAdvertBanner({ bannerIds: ids, merchantStoreId: id, picType: type }).then((res) => {
            loading.value = false;
            ElMessage.success('排序成功');
        });
    };
    const initBannerList = (picType) => {
        loading.value = true;
        return GetAdvertBanner({ id, picType }).then((res) => {
            loading.value = false;
            if (picType == 0) {
                PcAdList.splice(0, PcAdList.length);
                res.resultData.list.forEach((i) => {
                    PcAdList.push(i);
                });
            }
            else {
                H5AdList.splice(0, H5AdList.length);
                res.resultData.list.forEach((i) => {
                    H5AdList.push(i);
                });
            }
        });
    };
    // 编辑、删除轮播图
    const doClick = async (btntype, item, tabletype, key) => {
        const params = {
            id: item.row.id
        };
        if (btntype == 1) {
            await ElMessageBox.confirm('确定删除该轮播图？', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            });
            DelAdvertBanner(params).then((res) => {
                ElMessage.success('删除成功');
                initBannerList(tabletype);
            });
        }
        else {
            isEdit.value = true;
            picType.value = tabletype;
            dialogInfo.components = addType[key].comp;
            dialogInfo.pic = item.row.pic;
            dialogInfo.id = item.row.id;
            dialogInfo.picUrl = item.row.picUrl;
            dialogVisible.value = true;
        }
    };
    const addBtn = (key) => {
        isEdit.value = false;
        dialogVisible.value = true;
        dialogInfo.title = addType[key].name;
        dialogInfo.components = addType[key].comp;
        dialogInfo.name = '';
        dialogInfo.pic = '';
        dialogInfo.picUrl = '';
    };
    const cancel = () => {
        dialogVisible.value = false;
    };
    const submit = (form) => {
        loading.value = true;
        if (dialogInfo.components == addType.BigLogo.comp && !isEdit.value) {
            AddAdvertBanner({ picType: 0, merchantStoreId: id, pic: form.pic, picUrl: form.picUrl }).then((res) => {
                dialogVisible.value = false;
                initBannerList(0);
            }).catch(() => {
                loading.value = false;
            });
        }
        if (dialogInfo.components == addType.Logo.comp && !isEdit.value) {
            AddAdvertBanner({ picType: 1, merchantStoreId: id, pic: form.pic, picUrl: form.picUrl }).then((res) => {
                dialogVisible.value = false;
                initBannerList(1);
            }).catch(() => {
                loading.value = false;
            });
        }
        if (isEdit.value) {
            const params = {
                id: form.id,
                merchantStoreId: id,
                pic: form.pic,
                picType: picType.value,
                picUrl: form.picUrl
            };
            EditAdvertBanner(params).then((res) => {
                dialogVisible.value = false;
                initBannerList(picType.value);
                ElMessage.success('编辑成功');
            }).catch(() => {
                loading.value = false;
            });
        }
    };
    return {
        PcAdList,
        H5AdList,
        dialogVisible,
        dialogInfo,
        onDrag,
        doClick,
        addBtn,
        cancel,
        submit,
    };
}

import Upload from "@/components/upload/GoUpload.vue";
import { defineComponent } from "vue";
import Logo from "./index";
export default defineComponent({
    components: {
        Upload,
    },
    emits: ["cancel", "submit"],
    props: {
        data: {
            type: Object,
            require: true
        }
    },
    setup(props, { emit }) {
        const { dialogInfo, cancel, submit, ruleForm, rules, uploadUrl, beforeDetailUpload, uploadImg, remove } = Logo(props, { emit });
        return {
            dialogInfo,
            cancel,
            submit,
            ruleForm,
            rules,
            uploadUrl,
            beforeDetailUpload,
            uploadImg,
            remove
        };
    },
});
